
:root {
    // --max_page_width_medium: 960px;
    // --max_page_width_medium: 1260px;
    --max_page_width_medium: 950px;
    // --max_page_width_medium: 1060px;
    --max_page_width_large: 1440px;
    --side_spacing: 32px;
    --topbar_height: 100px;

    --spacing-l1: 32px;
    --spacing-l2: 20px;
    --spacing-m: 16px;
    --spacing-s2: 8px;
    --spacing-s1: 4px;

    --bg-color: #f2f2f2;
}

@media screen and (min-width: 1000px) {
    :root {
        --side_spacing: calc((100vw - var(--max_page_width_medium)) / 2);
    }
}