

.main {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    // perspective: 4px;
}

.overview {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.flex_content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}

.page_header {
    margin: 0 auto 0;
    width: 100%;
    // padding: var(--spacing-l1) var(--spacing-l1);
    padding: var(--spacing-m) var(--side_spacing);
    
    & > div {
        padding: 0 var(--spacing-m);
    }
    &:not(.no_border) > div {
        border-bottom: 1px solid var(--semanticColors-variantBorder);
    }
}

//TODO: flyt ind i makeStylesWithProps
.page_content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & .content_section:first-of-type {
        padding-top: var(--spacing-l1);
    }
    
    // z-index: 1;
    // background-color: white;
    // overflow: auto;

    // &.max_width_medium {
    //     align-items: center;

    //     & > .flex_content {
    //         max-width: 1440px;
    //     }
    // }

    // &.max_width_large > .flex_content {
    //     align-items: center;
        
    //     & > .flex_content {
    //         max-width: 1200px;
    //     }
    // }

    &_inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
    }
    //TODO: flyt ind i makeStylesWithProps
    .content_section {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        
        padding-top: var(--spacing-m);
        padding-bottom: var(--spacing-l1);
        background-color: var(--bg-color);

        &.grow1 {
            flex-grow: 1;
        }

        &_inner {

            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            width: 100%;
            padding-right: var(--spacing-l1);
            padding-left: var(--spacing-l1);

            &.align_center {
                justify-content: center;
                align-items: center;
            }
        }

        &.side_spacing_medium {
            padding-right: calc((100vw - var(--max_page_width_medium)) / 2);
            padding-left: calc((100vw - var(--max_page_width_medium)) / 2);
        }

        // &.side_spacing_large {
        //     padding-right: calc(var(--side_spacing) + var(--spacing-m));
        //     padding-left: calc(var(--side_spacing) + var(--spacing-m));
        // }
    }
}


