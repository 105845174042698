

body {
    margin: 0;
    min-height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    box-sizing: border-box;
    background-color: var(--bg-color);
    // font-size: var(--body_typography-font-size) !important;
}

*, *:before, *:after {
    box-sizing: inherit;
}

a {
    text-decoration: none;
    color: inherit;
}

#root {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}

// .suitemanager {
//     display: flex;
//     flex-direction: column;
//     flex-grow: 1;
//     min-height: 100%;
//     width: 100%;

//     // These vars comes from WP resources
//     font-size: var(--body_typography-font-size);
//     font-family: var(--body_typography-font-family);
//     font-weight: var(--body_typography-font-weight);
// }

// .tabs_container {
//     position: relative;
// }

// .fade-enter {
//     opacity: 0.01;
// }

// .fade-enter.fade-enter-active {
//     opacity: 1;
//     transition: opacity 500ms ease-in;
// }

// .fade-exit {
//     opacity: 1;
// }

// .fade-exit.fade-exit-active {
//     opacity: 0.01;
//     transition: opacity 200ms ease-in;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }

// .fade-out-to-installation {
//     opacity: 0;
//     transition: opacity 3000ms ease-in;
// }